import '@/components/helpers/date';
import {
  FeedingMethod,
  InvoiceTemplate,
  OrgSubscriptionType,
  PlanCallMethod,
  PlanCallUnit,
  useGetOrgSettingsQuery,
  useUpdateSettingsMutation,
} from '@/web-types';
import { OrgSettings as OrgSettingsType, OrgSettingsInput } from '@/common/types/settings';
import gql from 'graphql-tag';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { Timezones } from '@/common/utils/date';
import dayjs from 'dayjs';

gql`
  query GetOrgSettings {
    getCurrentOrganization {
      timezone
      name
      subscriptionType
    }
    getSettings
  }
  mutation UpdateSettings($merge: Settings!) {
    updateSettings(merge: $merge)
  }
`;

// TODO: remove the code labeled (REMOVE) and then modify calling code to deal with `loaded: false`
//

export type CurrentOrgSettings =
  | {
      readonly loaded: false;
      readonly updating: boolean;
      readonly settings: OrgSettingsType; // (REMOVE): see todo above
      readonly tz: string; // (REMOVE): see todo above
      readonly name: string; // (REMOVE): see todo above
      readonly subscriptionType: OrgSubscriptionType; // (REMOVE): see todo above
    }
  | {
      readonly loaded: true;
      readonly updating: boolean;
      readonly settings: OrgSettingsType;
      readonly tz: string;
      readonly name: string;
      readonly subscriptionType: OrgSubscriptionType;
    };
export type UpdateOrgSettings = (v: { merge: OrgSettingsInput }) => Promise<void>;
export type RefetchOrgSettings = () => void;

type OrgSettingsContextValue = {
  readonly updateSettings: UpdateOrgSettings;
  readonly value: CurrentOrgSettings;
};

// (REMOVE): see todo above
// The following is a temporary hack so that we don't have to handle the temporary situation of having no settings.
// The old code used defaults when this happens which isn't great, but for now it's better to unblock this and improve it in a subsequent step
// this provides temporary fallback defaults for when no settings are loaded
const fallbackSettingsTempHack: Omit<CurrentOrgSettings, 'loaded' | 'updating'> = {
  settings: {
    'feeding.roundupCalledNumbers': false,
    'feeding.feedingMethod': FeedingMethod.Targeted,
    'invoices.defaultTemplate': InvoiceTemplate.V2,
    'invoices.includeStatements': true,
    'invoices.sender.address': undefined,
    'invoices.sender.phone': undefined,
    'invoices.sender.email': undefined,
    'feeding.batch.maxLoadLbs': 10_000,
    'feeding.dmiPerHeadIncrement': 0.1,
    'feeding.afPerHeadIncrement': 0.25,
    'feeding.afIncrement': 100,
    'feeding.primaryCallMethod': { method: PlanCallMethod.ByDrop, unit: PlanCallUnit.AsFedLbs },
    'invoices.display.settings': undefined,
    'feeding.enableMicroMachines': false,
  },
  tz: Timezones.CT,
  name: '(unnamed)',
  subscriptionType: OrgSubscriptionType.Lite,
};

const defaultContextValue: OrgSettingsContextValue = {
  updateSettings: async () => {
    throw new Error('Not initialized');
  },
  value: {
    loaded: false,
    updating: false,
    ...fallbackSettingsTempHack, // (REMOVE): see todo above
  },
};

const OrgSettingsContext = createContext<OrgSettingsContextValue>(defaultContextValue);

export const OrgSettingsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const urqlcontext = useMemo(() => ({ additionalTypenames: ['Settings'] }), []);
  const [{ data }] = useGetOrgSettingsQuery({ context: urqlcontext });
  const [{ fetching: updating }, updateSettings] = useUpdateSettingsMutation();

  const tz = data?.getCurrentOrganization.timezone ?? Timezones.CT;

  useEffect(() => {
    // we have code that relies on this global being set
    dayjs.tz.setDefault(tz);
  }, [tz]);

  const value: OrgSettingsContextValue = useMemo(
    () => ({
      updateSettings: async (v: { merge: OrgSettingsInput }) => {
        await updateSettings({ merge: v.merge }, urqlcontext);
      },
      value: !data
        ? {
            loaded: false,
            updating,
            ...fallbackSettingsTempHack, // (REMOVE): see todo above
          }
        : {
            loaded: true,
            updating,
            settings: data.getSettings,
            tz: data.getCurrentOrganization.timezone ?? Timezones.CT,
            name: data.getCurrentOrganization.name ?? '(unnamed)',
            subscriptionType: data.getCurrentOrganization.subscriptionType ?? OrgSubscriptionType.Lite,
          },
    }),
    [data, updating, urqlcontext, updateSettings]
  );

  return <OrgSettingsContext.Provider value={value}>{children}</OrgSettingsContext.Provider>;
};

export const useOrgSettings: () => [CurrentOrgSettings, UpdateOrgSettings] = () => {
  const context = useContext(OrgSettingsContext);
  return [context.value, context.updateSettings];
};
