import { useLDClient } from 'launchdarkly-react-client-sdk';
import { VERSION } from '@/components/Providers/config';
import { useEffect } from 'react';
import { getUAInfo } from '@/components/helpers/ua';
import { useOrgId } from '@/components/Providers/auth/OrgIdProvider';

export const useLaunchDarklyIdentify = (email?: string | null) => {
  const client = useLDClient();
  const { orgId } = useOrgId();

  useEffect(() => {
    if (!client || !orgId) {
      return;
    }
    const userAgent = getUAInfo();
    let deviceType = 'web';
    if (userAgent.isIpadApp) {
      deviceType = 'ipad';
    } else if (userAgent.isIphoneApp) {
      deviceType = 'iphone';
    }
    // https://docs.launchdarkly.com/sdk/client-side/javascript/migration-2-to-3#working-with-built-in-and-custom-attributes
    client.identify({
      kind: 'multi',
      user: {
        kind: 'user',
        key: email ?? 'anonymous',
      },
      organization: {
        kind: 'organization',
        key: `${orgId}`,
      },
      device: {
        kind: 'device',
        type: deviceType,
        anonymous: true,
        custom: {
          clientVersion: VERSION,
          isRedbookApp: userAgent.isRedbookApp,
        },
      },
    });
  }, [client, email, orgId]);
};
