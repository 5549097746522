// Please keep header names lowercase because next.js converts them to lowercase in the req object.
export const orgIdCookieName = 'rb-org-id';
export const orgIdHeaderName = 'x-rb-org-id';

export const permissionsCookieName = 'rb-permissions';
export const permissionsHashCookieName = 'rb-permissions-hash';

export const apiPasswordHeaderName = 'x-rb-api-password';
export const apiPasswordQueryStringKey = 'password';

export const playwrightOrgNamePrefix = 'playwright-';
export const clientVersionHeaderName = 'x-rb-client-version';

export const callFeedV2CookieName = 'rb-call-feed-v2';
