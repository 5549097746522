import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren } from 'react';
import { useLaunchDarklyIdentify } from './useLaunchDarklyIdentify';
import { AUTH_ENABLED, LAUNCH_DARKLY_CLIENT_SIDE_ID } from '@/components/Providers/config';
import { useUser } from '@auth0/nextjs-auth0/client';

const NonProvider = ({ children }: PropsWithChildren) => <>{children}</>;

const LDProviderAuthDisabled = ({ children }: PropsWithChildren) => {
  useLaunchDarklyIdentify();
  return children;
};

const LDProviderAuthEnabled = ({ children }: PropsWithChildren) => {
  const { user } = useUser();
  useLaunchDarklyIdentify(user?.email);
  return children;
};

const LDProvider = withLDProvider<JSX.IntrinsicAttributes & PropsWithChildren>({
  clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
  // https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html
  options: {
    bootstrap: 'localStorage',
  },
})(AUTH_ENABLED ? LDProviderAuthEnabled : LDProviderAuthDisabled);

export const LaunchDarklyProvider = LAUNCH_DARKLY_CLIENT_SIDE_ID ? LDProvider : NonProvider;
